import React from "react";
import Layout from "../layouts/mainlayout";
import PostList from "../components/postlist";

export default class Blog extends React.Component {

    render() {
        return ( 
            <React.Fragment>
                <Layout>
                    <PostList />
                </Layout>
            </React.Fragment>
        );

    }
}