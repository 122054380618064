import React from "react";
import Layout from "../../layouts/mainlayout";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Player } from "video-react";
import  "video-react/dist/video-react.css";

export default class Reunions2010 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }
    render() {
        const { data } = this.props;
        const { photoIndex, isOpen } = this.state;
        const images = data.reunionImages.edges.map(({ node }) => (node.childImageSharp.fluid.src));
        console.log("our data: " , data.reunionImages.edges[4].node.childImageSharp.fluid.originalName);
        return (
            <Layout>
                <h1>2010 Reunion photos and video</h1>
                <div style={{width: "100%"}}>
                    <div>
                        <p>
                            Video 2010 Reunion dinner part 2
                        </p>
                        <p>Cake(s) are served to Aunt Margaret and Uncle Leon. Then Birthday cards are read to them by the "youngsters" Ariana, Justin, Isabelle, and Kathy. Followed by presents.</p>
                        <Player>
                            <source src="http://www.electricbear.com/videos/2011-DC-Reunion-2.mp4" />
                        </Player>
                    </div>
                    <p>
                        Photos 2010 Reunion
                    </p>
                    <p>
                        Click on an image below to see a larger version.
                    </p>
                    {isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={ images[(photoIndex + 1) % images.length] }
                            prevSrc={ images[(photoIndex + images.length - 1) % images.length] }
                            onCloseRequest={ () => this.setState({isOpen: false }) }
                            onMovePrevRequest={ () =>
                                this.setState({
                                    photoIndex: (photoIndex + images.length - 1) % images.length
                                })
                            }
                            onMoveNextRequest={ () =>
                                this.setState({
                                    photoIndex: (photoIndex + 1) % images.length
                                })
                            }
                            imageTitle={"Aunt Margaret and Uncle Leon's Birthdays - March 20-21 2010, Silver Springs MD."}
                            imageCaption={<div className="lightbox-image-captions"><span>{data.reunionImages.edges[photoIndex].node.childImageSharp.fluid.originalName}</span> <span>{photoIndex + 1} of {images.length}</span></div>}
                        />
                    )}
                </div>
                <div className="image-thumbs">
                    {data.reunionImages.edges.map( ({ node }, index) => (
                        <div
                            className="image-wrapper image-thumbs-wrapper"
                            key={node.id}
                            onClick={() => this.setState({ isOpen: true, photoIndex: index })}
                        >
                            <Img
                                key={index}
                                title={node.childImageSharp.fluid.originalName}
                                alt={`${node.childImageSharp.fluid.originalName}`}
                                fluid={ node.childImageSharp.fluid }
                            />
                        </div>
                    ))}
                </div>
            </Layout>
        );
    }
}

export const query = graphql`
    query {
        reunionImages: allFile (filter:  { sourceInstanceName: { eq: "2010" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        fluid (maxWidth: 1024) {
                            ...GatsbyImageSharpFluid
                            originalName
                            src
                        }
                    }
                }
            }
        }
    }
`