import React from "react";
import BackToTopArrow from "../images/back-to-top-arrow.png";

export default class BackToTop extends React.Component {
    render(){
        return (
            <div className={"back-to-top-wrapper"} id={"back_top"}>
                <a href={"#top"} className={"back-to-top"} title={"Back to top"}>
                    <svg viewBox={"0 0 100 100"} width={"1rem"} height={"1rem"} tabIndex={"-1"} role={"img"} aria-labelledby={"title"}>
                        <title id="title">Back to top</title>
                        <path d={"M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"} transform={"translate(0 0) rotate(90 50 50)"} fill={"#fff"} stroke={"#fff"}></path>
                        <img src={BackToTopArrow} alt={"Back to top arrow"} width={"100%"} height={"100%"} />
                    </svg>
                </a>
            </div>
        );
    }
}