import React from "react";
import Layout from "../../layouts/mainlayout";
import { Link } from "gatsby";

export default class Index extends React.Component {
    render() {
        
        return (
            <Layout>
                <h1>Reunions</h1>
                <div style={{width: "100%"}}>
                    <div>
                        <p>
                            Aunt Margert's 90th and Uncle Leon's 85th birthday and <Link to={"reunions/2010-reunion"}>reunion in Maryland 2010</Link>
                        </p>
                    </div>
                </div>
            </Layout>
        )
    }
}