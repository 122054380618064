import React from "react";
import PropTypes from "prop-types";

export default class MainContent extends React.Component {
    static propTypes = {
        data: PropTypes.func
    }
    render() {
        const {children} = this.props;
        return (
            <main className={"mainbody"}>
                {children}
            </main>
        )
    }
}