import React from "react";
import { Link } from "gatsby";
import { Collapse } from 'reactstrap';

export default class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.onEntering = this.onEntering.bind(this);
        this.onEntered = this.onEntered.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = { collapse: false, status: 'Closed' };
    }

    onEntering() {
        this.setState({ status: 'Opening...' });
    }

    onEntered() {
        this.setState({ status: 'Opened' });
    }

    onExiting() {
        this.setState({ status: 'Closing...' });
    }

    onExited() {
        this.setState({ status: 'Closed' });
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }
    
    render() {
        return (
            <aside className={"sidebar"}>
                <nav className="side-nav" role={"navigation"}>
                    <Link to={"/"}>{"Home"}</Link>
                    <button onClick={this.toggle}>Blogs</button>
                    <Collapse
                        isOpen={this.state.collapse}
                        onEntering={this.onEntering}
                        onEntered={this.onEntered}
                        onExiting={this.onExiting}
                        onExited={this.onExited}
                    >
                        <Link id="collapse-target" className="secondary-nav-link" to={"blogs"}>All</Link>
                        <Link className="secondary-nav-link" to={"tags"}>Tags</Link>
                    </Collapse>
                    <button onClick={this.toggle}>Reunions</button>
                    <Collapse
                        isOpen={this.state.collapse}
                        onEntering={this.onEntering}
                        onEntered={this.onEntered}
                        onExiting={this.onExiting}
                        onExited={this.onExited}
                    >
                        <Link id="collapse-target" className="secondary-nav-link" to={"reunions/"}>All</Link>
                        <Link className="secondary-nav-link" to={"reunions/2010-reunion"}>2010 Maryland</Link>
                    </Collapse>
                    <Link to={"about"}>About</Link>
                        
                </nav>
            </aside>
        );
    }
}
