import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Sidebar from "../components/sidebar";
import MainContent from "../components/maincontent";
import BackToTop from "../components/backtotop";

export default class Layout extends React.Component {
    render () {
        const {children} = this.props;
        return (
            <div className={"page"}>
                <Header />
                <div className={"sidebar-main-container"}>
                    <Sidebar />
                    <MainContent>
                        {children}
                        <BackToTop />
                    </MainContent>
                </div>
                <Footer />
            </div>
        );
    }
}