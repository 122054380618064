import React from "react";

export default class Footer extends React.Component {
    render() {
        return (
            <footer>
                <ul>
                    <li>&copy; Copyright {new Date().getFullYear()}</li>
                    <li>A DTB Production &trade;</li>
                </ul>
            </footer>
        );
    }
}