  import React from "react";
//   import {graphql} from "gatsby";
  import Layout from "../layouts/mainlayout";
  
  export default class Index extends React.Component {
      render() {
        //   const {data} = this.props;
          return (
              <React.Fragment>
              <Layout>
                    <div>
                        <h1>Demo images plugins</h1>
                        <div>
                            <p>
                                Let's try out some typography and different types of gatsby images on this demo page.
                            </p>
                            <blockquote>
                                <p>
                                    There is indeed an element of luck, and no, there isn’t. The prepared
                                    mind sooner or later finds something important and does it. So yes, it
                                    is luck.{" "}
                                    <em>
                                        The particular thing you do is luck, but that you do something is
                                        not.
                                    </em>
                                </p>
                            </blockquote>
                        </div>
                    </div>
                </Layout>
              </React.Fragment>
          );
      }
  }

// export const query = graphql`
// query {
//   file(relativePath: { eq: "images/waterfordWinter2003g.jpg" }) {
//     childImageSharp {
//       # Specify the image processing specifications right in the query.
//       # Makes it trivial to update as your page's design changes.
//       fixed(width: 350) {
//         ...GatsbyImageSharpFixed
//       }
//     }
//   }
// }
// `
  