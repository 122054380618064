import React from "react";
import Layout from "../layouts/mainlayout";

export default class NotFoundPage extends React.Component {
    render() {
        return(
            <Layout>
                <h1>NOT FOUND</h1>
                <p>The page at the end of the rainbow doesn&#39;t exist... the pain the pain.</p>
            </Layout>
        );
    }
}
