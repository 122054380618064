import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../layouts/mainlayout";
import { kebabCase } from "lodash";
import Img from "gatsby-image";

export default class BlogPosts extends React.Component {

    render() {
        const post = this.props.data.markdownRemark ? this.props.data.markdownRemark : null;
        return (
            <Layout>
                <div className="blog-page-content">
                    <h1>{post.frontmatter.title}</h1>
                    <div className={"post-attribution"}>
                        <div>Written by: <b>{post.frontmatter.author}</b></div>
                        <span className="readingTime" title={`${post.timeToRead} min read`}></span>
                        <span className="middotDivider"></span>
                        <time dateTime={post.frontmatter.date}>{post.frontmatter.date}</time>
                    </div>
                    {/* <Img fluid={post.frontmatter.ogImage.childImageSharp.fluid} /> */}
                    <div dangerouslySetInnerHTML={{ __html: post.html }} />
                    <hr className="section-divider" />
                    {post.frontmatter.tags && post.frontmatter.tags.length > 0 
                        ?   (   <aside>
                                    <b>Tags</b> in this blog
                                    <ul className={"blog-tags"}>
                                        {post.frontmatter.tags.map((tag, index) => (
                                            <li key={index}>
                                                <Link to={`/tags/${kebabCase(tag)}/`}>
                                                    {tag}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </aside>
                            )
                        : null
                    }
                </div>
            </Layout>
        );
    }
}
  
  export const query = graphql`
    query($slug: String!) {
      markdownRemark(fields: { slug: { eq: $slug } }) {
        html
        rawMarkdownBody
        frontmatter {
            ogImage {
                publicURL
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            title
            author
            date(formatString: "MMMM DD, YYYY")
            tags
        }
        timeToRead
      }
    }
  `