import React from "react";
import {graphql} from "gatsby";
import Layout from "../layouts/mainlayout";

export default class Files extends React.Component {
    render() {
        const {data} = this.props;
        return (
            <Layout>
                <div>
                    <h1>My Site's Files</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>extension</th>
                                <th>Created</th>
                                <th>Modified</th>
                                <th>prettySize</th>
                                <th>relativePath</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.allFile.edges.map(({ node }, index) => (
                            <tr key={index}>
                                <td>{node.name}</td>
                                <td>{node.extension}</td>
                                <td>{node.birthTime}</td>
                                <td>{node.modifiedTime}</td>
                                <td>{node.relativePath}</td>
                                <td>{node.prettySize}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </Layout>
        );
    }
}

export const query = graphql`
    query {
        allFile (sort: {fields: [modifiedTime], order: DESC} filter: {relativePath: { regex: "/pages/" } } ) {
            edges {
                node {
                    relativePath
                    extension
                    birthTime(fromNow: true)
                    modifiedTime(fromNow: true)
                    name
                    prettySize
                }
            }
        }
    }
`