import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts/mainlayout";
import Img from "gatsby-image";

export default class About extends React.Component {

  render() {
    const {data} = this.props;

    return (
        <React.Fragment>
            <Layout>
                <h1>About {data.site.siteMetadata.title}</h1>
                <p>
                    This is the latest incarnation of a website I started back in the early twenty first century (smile).
                    <br />It started pretty small with the idea being to bring updates, news, and importantly share photos and history with the family wherever they were.
                </p>
                <div className="image-wrapper">
                    <Img
                        title="Seven Millers"
                        alt="Seven Millers"
                        fluid={data.aboutImage.childImageSharp.fluid}
                    />
                </div>
                <p>
                    <br />We have been many places and more places to go.
                    <br />We have grown and lost some over the years.
                    <br />We are bound by blood.
                    <br />We are our history.
                    <br />We are our future.
                    <br /><strong>We are still here! We are experience!</strong>
                </p>
            </Layout>
        </React.Fragment>
    );
  }
}

export const query = graphql`
    query {
        aboutImage: file(relativePath: {eq: "seven-millers.jpg"}) {
            childImageSharp {
                    fluid (maxWidth: 1024) {
                        ...GatsbyImageSharpFluid
                    }
            }
        }
        site {
            siteMetadata {
                title
            }
        }
    }
`