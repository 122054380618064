import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import PropTypes from "prop-types";

class PostList extends React.Component {
    render() {
        const { data } = this.props
        return (
            <div className={"post-list"}>
                <h2>Blog posts</h2>
                {this.props.location
                    ? <h5>Latest {data.allMarkdownRemark.totalCount} posts</h5>
                    : <h6>{data.allMarkdownRemark.totalCount} posts for your reading pleasure.</h6>
                }
                    {data.allMarkdownRemark.edges.map(({ node }) => (
                        <div className={"media"} key={node.id}>
                            <div className={"asset"}>
                                <Link to={node.fields.slug}>
                                    <picture>
                                        <source
                                            type="image/webp"
                                            srcSet={node.frontmatter.ogImage.childImageSharp.resolutions.srcWebp}
                                        />
                                        <source srcSet={node.frontmatter.ogImage.childImageSharp.resolutions.srcSet} />
                                        <img src={node.frontmatter.ogImage.childImageSharp.resolutions.src} alt={node.frontmatter.title} />
                                    </picture>
                                </Link>
                            </div>
                            <div className={"content"}>
                                <Link to={node.fields.slug}>
                                    <h5>{node.frontmatter.title}</h5>
                                </Link>
                                <Link to={node.fields.slug}>
                                    {node.excerpt}
                                </Link>
                                <div className={"post-attribution"}>
                                    <div>{node.frontmatter.author}</div>
                                    <span className="readingTime" title={`${node.timeToRead} min read`}></span>
                                    <span className="middotDivider"></span>
                                    <time dateTime={node.frontmatter.date}>{node.frontmatter.date}</time>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        );
    }
}
PostList.propTypes = {
    data: PropTypes.func,
    location: PropTypes.string
}

export default () => (
<StaticQuery
query = {graphql`
    query {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC } filter: { frontmatter: { draft: { ne: true } } }) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        ogImage {
                            publicURL
                            childImageSharp {
                                resolutions(height: 150, fit: INSIDE) {
                                    srcWebp
                                    src
                                }
                                resize(height: 150, cropFocus: WEST) {
                                    aspectRatio
                                }
                            }
                        }
                        title
                        author
                        date(formatString: "MMMM DD, YYYY")
                    }
                    fields {
                        slug
                    }
                    excerpt(pruneLength: 300)
                    html
                    timeToRead
                }
            }
        }
    }
`}
render={(data, count) => <PostList data={data} count={count} />}
/>
)