import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import PropTypes from "prop-types";
import logo from "../images/exp7e.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Collapse, Navbar, Nav, NavItem, NavbarToggler } from 'reactstrap';

export default class Header extends React.Component {

    static propTypes = {
        data: PropTypes.func,
        children: PropTypes.func
    }

    constructor (props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <React.Fragment>
                <StaticQuery
                    query={graphql`
                            query {
                                site {
                                    siteMetadata {
                                        title
                                    }
                                }
                            }
                        `
                    }

                    render={data => (
                        <Navbar className="top-nav" color="light" light expand="md">
                            <Link className="navbar-brand" to={"/"}><img src={logo} alt={data.site.siteMetadata.title} width={"183"} height={"45"} /></Link>
                            <NavbarToggler onClick={this.toggle} aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" />
                            <Collapse isOpen={this.state.isOpen} id={"top navbarSupportedContent"} role={"navigation"} className="collapse navbar-collapse" navbar>
                                <Nav className="ml-auto" navbar>
                                    <NavItem active>
                                        <Link className="nav-link" to={"/"}>Home</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link" to={"blogs"}>Blog</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link" to={"about"}>About</Link>
                                    </NavItem>
                                </Nav>
                            </Collapse>
                        </Navbar>
                    )}
                />
            </React.Fragment>
        );
    }
}
