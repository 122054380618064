import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../layouts/mainlayout";

export default class TagsTemplate extends React.Component {
    static propTypes = {
        pageContext: PropTypes.shape({
            tag: PropTypes.string.isRequired,
        }),
        data: PropTypes.shape({
            allMarkdownRemark: PropTypes.shape({
                totalCount: PropTypes.number.isRequired,
                edges: PropTypes.arrayOf(
                    PropTypes.shape({
                        node: PropTypes.shape({
                            fields: PropTypes.shape({
                                slug: PropTypes.string.isRequired,
                            }),
                            frontmatter: PropTypes.shape({
                                title: PropTypes.string.isRequired,
                            }),
                        }),
                    }).isRequired
                ),
            }),
        }),
      }
    render() {
        const { pageContext, data } = this.props;
        const { tag } = pageContext;
        const { edges, totalCount } = data.allMarkdownRemark;
        const tagHeader = `${totalCount} post${
            totalCount === 1 ? "" : "s"
        } tagged with "${tag}"`;
    
        return (
            <Layout>
                <h5>{tagHeader}</h5>
                <ul className="tag-list">
                    {edges.map(({ node }) => {
                        const { title, author, date } = node.frontmatter;
                        const path = node.fields.slug;
                        return (
                            <li key={path}>
                                <Link to={path}>
                                    {title}
                                </Link>
                                    <span className="post-attribution">
                                        {author}
                                        <span className="middotDivider"></span>
                                        <time dateTime={date}></time>{date}
                                    </span>
                            </li>
                        )
                    })}
                </ul>
                <Link to="/tags/">All tags in posts</Link>
            </Layout>
        );
    }
}

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            ogImage {
                publicURL
                children {
                ... on ImageSharp {
                    resolutions(height: 64) {
                    ...GatsbyImageSharpResolutions_withWebp_noBase64
                        }
                    }
                }
            }
            title
            author
            date(formatString: "MMMM DD, YYYY")
          }
          fields {
              slug
          }
        }
      }
    }
  }
`