import React from "react";
import Layout from "../layouts/mainlayout";
import PostList from "../components/postlist";
import Img from "gatsby-image";
import { graphql } from "gatsby";

export default class Index extends React.Component {

    render() {
        const {data} = this.props;
        return ( 
            <React.Fragment>
                <Layout>
                    <div className="image-wrapper">
                        <Img
                            title="The Canoe"
                            alt="The Canoe"
                            fluid={data.homepageImage.childImageSharp.fluid}
                        />
                    </div>
                    <p>
                        <span className="lead-in">So</span> what is this "Experience7" anyway?
                        <br />This is the latest incarnation of a website I started back in the early twenty first century (smile).
                        <br />It started pretty small with the idea being to bring updates, news, and importantly share photos and history with the family wherever they were.
                        <br />At that time we were 7 siblings in the Miller clan. Things have changed; as they always do; but the spirit and intention of this site continues.
                    </p>
                    <PostList location={"homepage"} />
                </Layout>
            </React.Fragment>
        );

    }
}

export const query = graphql`
    query {
        homepageImage: file(relativePath: {eq: "Canoe.jpg"}) {
            childImageSharp {
                    fluid (maxWidth: 1024) {
                        ...GatsbyImageSharpFluid
                    }
            }
        }
    }
`